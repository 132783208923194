import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Button from '../components/Button';
import Footer from '../components/Footer';
import Feature from '../components/Feature';
import IPhone from '../components/IPhone';
import Container from '../components/Container';
import demoMovie from '../images/demo.mp4';
import { rhythm } from '../utils/typography';

const HTMLHead = () => (
  <Helmet
    title="Planl Demo"
    meta={[
      {
        name: 'description',
        content: 'Planl Demo uitleg pagina'
      },
      {
        name: 'keywords',
        content:
          'Afspraak maken,Planl,zorgverleners,informatie,tandarts,huisarts,fysio,contact,email,demo'
      }
    ]}
  />
);

const Navigation = () => (
  <Navbar>
    <Navbar.Header>
      <Link to="/" style={{ textDecoration: 'none' }}>
        Planl
      </Link>
    </Navbar.Header>
    <Navbar.Menu>
      <Navbar.Menu.Item>
        <Link to="/info" style={{ textDecoration: 'none' }}>
          Informatie voor zorgverleners
        </Link>
      </Navbar.Menu.Item>
      <Navbar.Menu.Item>
        <Link to="/contact" style={{ textDecoration: 'none', marginLeft: rhythm(1) }}>
          Contact
        </Link>
      </Navbar.Menu.Item>
    </Navbar.Menu>
  </Navbar>
);

const iphoneDemo = (
  <IPhone>
    <video
      autoPlay
      loop
      muted
      css={{
        height: '100%'
      }}
    >
      <source src={demoMovie} type="video/mp4" />
    </video>
  </IPhone>
);

export default function Demo() {
  return (
    <div>
      {HTMLHead}
      <Navigation />
      <Hero>
        <h1>Planl demo</h1>
        <p>Maak kennis met het gebruiksvriendelijkste afsprakensysteem voor de eerstelijnszorg.</p>
        <p>
          Klik op &quot;Voor de patiënt&quot; om te bekijken hoe het systeem er voor een patiënt
          uitziet. Je kan dan een afspraak maken bij onze demo praktijk. Normaal krijg je een SMS
          code opgestuurd, <strong>nu kun je gewoon de code 0000 invoeren om door te gaan.</strong>{' '}
          Als je een afspraak hebt gemaakt komt deze in het adminplanl voor de praktijk te staan.
          Klik hiervoor op Voor de &quot;zorgverlener&quot;.
        </p>
        <p>
          Klik op &quot;Voor de zorgverlener&quot; om de achterkant van Planl te bekijken. Nieuw
          gemaakte afspraken bij de demo praktijk komen hier binnen. Je kunt een afspraak
          goedkeuren, wijzigen of verwijderen. De patiënt wordt hiervan automatisch op de hoogte
          gesteld.
        </p>
        <p>
          Login met: <br />
          email: <strong>demo@planl.nl</strong> <br />
          wachtwoord: <strong>planldemo</strong>
        </p>
        <Button
          onClick={() => {
            window.open('https://demo.planl.nl/providers/Planl-Zorgverleners');
          }}
        >
          Voor de patiënt
        </Button>
        <Button
          onClick={() => {
            window.open('https://demo.planl.nl/admin/');
          }}
          style={{ marginLeft: rhythm(1) }}
        >
          Voor de zorgverlener
        </Button>
      </Hero>
      <Container>
        <Feature.Group>
          <Feature
            title="Een afspraak maken"
            description="Kies heel gemakkelijk waar, wanneer en bij wie je een afspraak wil."
            textStyle={{ textAlign: 'center', marginBottom: 50, maxWidth: 300 }}
            image={iphoneDemo}
          />
        </Feature.Group>
      </Container>
      <Footer />
    </div>
  );
}
